/* eslint-disable @typescript-eslint/unbound-method */
import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, inject, type OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { JsonApiClientService, ResourceTypes } from '@big-direkt/json-api-client';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiButtonGroupComponent, type ButtonGroupDataModel } from '@big-direkt/ui/button-group';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiCurrencyInputComponent } from '@big-direkt/ui/input';
import { UiSelectComponent, type SelectOption } from '@big-direkt/ui/select';
import { UiSpinnerModule } from '@big-direkt/ui/spinner';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { states, type ServiceToolsModel } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { EMPTY, catchError, map, type Observable } from 'rxjs';
import { ContributionCalculatorTaxonomyMapper } from '../mappers/contribution-calculator-taxonomy.mapper';
import { type ContributionCalculatorForm } from '../models/contribution-calculator-form.model';
import { type ContributionCalculatorFormResult } from '../models/contribution-calculator-result';
import { type InsuranceType } from '../models/insurance-types.model';
import { type TaxonomyData } from '../models/insurant-calculator.model';
import { ContributionCalculatorFormService } from '../services/contribution-calculator-form.service';
import { ContributionCalculatorTypeService } from '../services/contribution-calculator-type.service';
import { ContributionCalculatorResultComponent } from './contribution-calculator-result.component';
@Component({
    selector: 'big-service-tool-contribution-calculator',
    templateUrl: './contribution-calculator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        ContributionCalculatorResultComponent,
        CurrencyPipe,
        DecimalPipe,
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAlertComponent,
        UiButtonComponent,
        UiButtonGroupComponent,
        UiCurrencyInputComponent,
        UiFormRowComponent,
        UiSelectComponent,
        UiSpinnerModule,
    ],
})
export class ContributionCalculatorComponent extends ServiceToolBaseComponent<ContributionCalculatorForm> implements OnInit {
    private readonly yesLabel = 'stContributionCalculator.label.yes';
    private readonly noLabel = 'stContributionCalculator.label.no';

    private readonly environmentService = inject(EnvironmentService);
    private readonly formConfigService = inject(ContributionCalculatorFormService);
    private readonly jsonApi = inject(JsonApiClientService);
    private readonly taxonomyMapper = inject(ContributionCalculatorTaxonomyMapper);
    private readonly contributionCalculatorTypeService = inject(ContributionCalculatorTypeService);

    public readonly errorPhoneNumber = this.environmentService.errorPhoneNumber;
    public readonly selectErrorOverrides = { required: 'stContributionCalculator.error.selectRequired' };

    @HostBinding('class') @Input() public classList = 'block';
    public override translationScope = 'stContributionCalculator';

    public optionEmployeeCompulsory: InsuranceType = 'employeeCompulsory';
    public optionEmployeeVoluntary: InsuranceType = 'employeeVoluntary';
    public optionIndependent: InsuranceType = 'independent';
    public optionOtherVoluntary: InsuranceType = 'otherVoluntary';
    public optionRetiredCompulsory: InsuranceType = 'retiredCompulsory';
    public optionRetiredVoluntary: InsuranceType = 'retiredVoluntary';
    public calculationResult?: ContributionCalculatorFormResult;

    public insuranceTypeOptions: SelectOption<InsuranceType>[] = [
        { scope: this.translationScope, value: this.optionEmployeeCompulsory, key: 'label.employeeCompulsory' },
        { scope: this.translationScope, value: this.optionEmployeeVoluntary, key: 'label.employeeVoluntary' },
        { scope: this.translationScope, value: this.optionRetiredCompulsory, key: 'label.retiredCompulsory' },
        { scope: this.translationScope, value: this.optionRetiredVoluntary, key: 'label.retiredVoluntary' },
        { scope: this.translationScope, value: this.optionIndependent, key: 'label.independent' },
        { scope: this.translationScope, value: this.optionOtherVoluntary, key: 'label.otherVoluntary' },
    ];

    public childrenUnder25Options: SelectOption<number>[] = [
        { scope: this.translationScope, value: 0, key: 'label.optionNoChild' },
        { scope: this.translationScope, value: 1, key: 'label.optionOneChild' },
        { scope: this.translationScope, value: 2, key: 'label.optionTwoChildren' },
        { scope: this.translationScope, value: 3, key: 'label.optionThreeChildren' },
        { scope: this.translationScope, value: 4, key: 'label.optionFourChildren' },
        { scope: this.translationScope, value: 5, key: 'label.optionFiveChildren' },
    ];

    public stateOfResidencyOptions: SelectOption<string>[] = states.map(state => ({
        key: state,
        value: state,
    }));

    public childrenOptions: ButtonGroupDataModel<boolean>[] = [
        {
            value: true,
            label: this.yesLabel,
        },
        {
            value: false,
            label: this.noLabel,
        },
    ];

    public taxonomyTerms$: Observable<never> | Observable<TaxonomyData> | undefined;
    public hasDataLoadingError = false;
    public isLoading = false;

    public constructor() {
        super();

        this.form = this.formBuilder.group({
            insuranceType: new FormControl<InsuranceType | ''>('', [Validators.required]),
        });
    }

    public ngOnInit(): void {
        this.isLoading = true;

        this.taxonomyTerms$ = this.jsonApi.find<ServiceToolsModel>(ResourceTypes.TaxonomyTermServiceTools).pipe(
            map(rawTaxonomies => {
                this.isLoading = false;

                return this.taxonomyMapper.mapTaxonomies(rawTaxonomies);
            }),
            catchError(() => {
                this.isLoading = false;
                this.hasDataLoadingError = true;

                return EMPTY;
            }),
        );
    }

    public submit(submitEvent: Event, taxonomyTerms: TaxonomyData): void {
        submitEvent.preventDefault();

        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (!this.form?.valid) {
            return;
        }

        this.calculationResult = this.contributionCalculatorTypeService.calculateByInsuranceType(this.form, taxonomyTerms);
    }

    public onInsuranceTypeChange(insuranceType: InsuranceType): void {
        this.calculationResult = undefined;

        const specificForm = this.formConfigService.getInsuranceSpecificForm(insuranceType);
        this.form?.setControl('specificFields', specificForm);
    }

    public onHasChildrenChange(hasChildren: boolean): void {
        const childrenUnder25Control = this.form?.controls.specificFields?.controls.childrenUnder25;

        if (hasChildren) {
            childrenUnder25Control?.enable();
        } else {
            childrenUnder25Control?.disable();
        }
    }

    public hasControlRequiredValidator(control: FormControl): boolean {
        return control.hasValidator(Validators.required);
    }
}

export default ContributionCalculatorComponent;
